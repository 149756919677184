const numbers = {
  "0": { en: "one", se: "noll" },
  "1": { se: "en" },
  "2": { se: "två" },
  "3": { se: "tre" },
  "4": { se: "fyra" },
  "5": { se: "fem" },
  "6": { se: "sex" },
  "7": { se: "sju" },
  "8": { se: "åtta" },
  "9": { se: "nio" },
  "10": { se: "tio" },
  "11": { se: "elva" },
  "12": { se: "tolv" },
  "13": { se: "tretton" },
  "14": { se: "fjorton" },
  "15": { se: "femton" },
  "16": { se: "sexton" },
  "17": { se: "sjutton" },
  "18": { se: "arton" },
  "19": { se: "nitton" },
  "20": { se: "tjugo" },
  "21": { se: "tjugoen" },
  "22": { se: "tjugotvå" },
  "23": { se: "tjugotre" },
  "24": { se: "tjugofyra" },
  "25": { se: "tjugofem" },
  "26": { se: "tjugosex" },
  "27": { se: "tjugosju" },
  "28": { se: "tjugoåtta" },
  "29": { se: "tjugonio" },
  "30": { se: "trettio" },
  "31": { se: "trettioen" },
  "32": { se: "trettiotvå" },
  "33": { se: "trettiotre" },
  "34": { se: "trettiofyra" },
  "35": { se: "trettiofem" },
  "36": { se: "trettiosex" },
  "37": { se: "trettiosju" },
  "38": { se: "trettioåtta" },
  "39": { se: "trettionio" },
  "40": { se: "fyrtio" },
  "41": { se: "fyrtioen" },
  "42": { se: "fyrtiotvå" },
  "43": { se: "fyrtiotre" },
  "44": { se: "fyrtiofyra" },
  "45": { se: "fyrtiofem" },
  "46": { se: "fyrtiosex" },
  "47": { se: "fyrtiosju" },
  "48": { se: "fyrtioåtta" },
  "49": { se: "fyrtionio" },
  "50": { se: "femtio" },
  "51": { se: "femtioen" },
  "52": { se: "femtiotvå" },
  "53": { se: "femtiotre" },
  "54": { se: "femtiofyra" },
  "55": { se: "femtiofem" },
  "56": { se: "femtiosex" },
  "57": { se: "femtiosju" },
  "58": { se: "femtioåtta" },
  "59": { se: "femtionio" },
  "60": { se: "sextio" },
  "61": { se: "sextioen" },
  "62": { se: "sextiotvå" },
  "63": { se: "sextiotre" },
  "64": { se: "sextiofyra" },
  "65": { se: "sextiofem" },
  "66": { se: "sextiosex" },
  "67": { se: "sextiosju" },
  "68": { se: "sextioåtta" },
  "69": { se: "sextionio" },
  "70": { se: "sjuttio" },
  "71": { se: "sjuttioen" },
  "72": { se: "sjuttiotvå" },
  "73": { se: "sjuttiotre" },
  "74": { se: "sjuttiofyra" },
  "75": { se: "sjuttiofem" },
  "76": { se: "sjuttiosex" },
  "77": { se: "sjuttiosju" },
  "78": { se: "sjuttioåtta" },
  "79": { se: "sjuttionio" },
  "80": { se: "åttio" },
  "81": { se: "åttioen" },
  "82": { se: "åttiotvå" },
  "83": { se: "åttiotre" },
  "84": { se: "åttiofyra" },
  "85": { se: "åttiofem" },
  "86": { se: "åttiosex" },
  "87": { se: "åttiosju" },
  "88": { se: "åttioåtta" },
  "89": { se: "åttionio" },
  "90": { se: "nittio" },
  "91": { se: "nittioen" },
  "92": { se: "nittiotvå" },
  "93": { se: "nittiotre" },
  "94": { se: "nittiofyra" },
  "95": { se: "nittiofem" },
  "96": { se: "nittiosex" },
  "97": { se: "nittiosju" },
  "98": { se: "nittioåtta" },
  "99": { se: "nittionio" },
  "100": { se: "hundra" },
};

export default numbers;
