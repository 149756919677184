import React from "react";
import { useState } from "react";
import "./App.css";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import numbers from "./numbers";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    textAlign: "center",
    // width: '80vh'
  },
  container: {
    height: "80vh",
  },
  gridNumber: {
    height: "20vh",    
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  grid: {
    height: "60vh",
    // backgroundColor: "green",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  paper: {
    fontSize: 64,
  },
  paperNumber: {
    alignItems: "center",
    padding: theme.spacing(4),
  },
  numberInput: {
    fontSize: 50,
  },
}));

function App() {
  const [message, setMessage] = useState("");
  const classes = useStyles();

  const handleNumberChange = (event) => {
    if (numbers[event.target.value] && numbers[event.target.value]["se"]) {
      let msg = `${event.target.value} in Swedish is ${numbers[event.target.value]["se"]}`
      setMessage(msg);
    } else {
      setMessage("");
    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container className={classes.container}>
          <Grid item className={classes.gridNumber} xs={12}>
            <Paper className={`${classes.paperNumber}`}>
              <TextField
                id="standard-basic"
                label="Number"
                onChange={handleNumberChange}
                InputProps={{
                  classes: {
                    input: classes.numberInput,
                  },
                }}
              />
            </Paper>
          </Grid>
          <Grid item className={classes.grid} xs={12}>
            <Paper className={classes.paper} elevation={0}>{message}</Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default App;
